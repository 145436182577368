import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum CorporationFetchStatus {
    OPEN = "OPEN",
    TECHNICAL_ERROR = "TECHNICAL_ERROR",
    MULTIPLE_RESULTS = "MULTIPLE_RESULTS",
    ZERO_RESULTS = "ZERO_RESULTS",
    SUCCESSFUL = "SUCCESSFUL",
}

export enum CorporationDetailSourceStatus {
    SUCCESS = "SUCCESS",
    TECHNICAL_ERROR = "TECHNICAL_ERROR",
    DISPLAY_COUNT_GREATER_ONE = "DISPLAY_COUNT_GREATER_ONE",
    DISPLAY_COUNT_ZERO = "DISPLAY_COUNT_ZERO",
}

export enum CorporationStatusTaner {
    UNKNOWN = "UNKNOWN",
    USELESS = "USELESS",
    EXCELLENT_IDEA = "EXCELLENT_IDEA",
    CHECK_LATER = "CHECK_LATER",
}

export enum CorporationMemberType {
    DIRECTOR = "DIRECTOR",
    PROXY = "PROXY",
    LIQUIDATOR = "LIQUIDATOR",
}

export interface CorporationMetaData {
    readonly federalStateId: string;
    readonly registerCourtId: string;
    readonly registerNumber: string;
    readonly name: string;
    readonly city: string;
}

export interface CorporationMetaDataResult {
    readonly displayCount: number;
    readonly actualCount: number;
    readonly corporationMetaData: CorporationMetaData[];
}

export interface CorporationMember {
    readonly type: CorporationMemberType;
    readonly givenName: string | null;
    readonly familyName: string | null;
    readonly dateOfBirth: string | null;
}

export interface Corporation {
    readonly id: string;
    readonly created: Date;
    readonly lastFetchTry: Date;
    readonly lastFetchStatus: CorporationFetchStatus;
    readonly federalStateId: string;
    readonly registerCourtId: string;
    statusTaner: CorporationStatusTaner;
    readonly registerNumber: string;
    readonly name: string;
    readonly city: string;
    readonly registered: string | null;
    readonly description: string | null;
    readonly shareCapital: string | null;
    readonly shareCapitalCurrency: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly addressCity: string | null;
    readonly youngestMemberYearOfBirth: number | null;
    readonly oldestMemberYearOfBirth: number | null;
    readonly businessScore: number | null;
    readonly members: CorporationMember[];
}

export interface CorporationOverview {
    readonly id: string;
    readonly created: Date;
    readonly lastFetchTry: Date;
    readonly federalStateId: string;
    readonly registerCourtId: string;
    readonly registerNumber: string;
    readonly name: string;
    readonly city: string;
}

export interface CorporationAdditionalData {
    readonly registered: string | null;
    readonly description: string | null;
    readonly shareCapital: string | null;
    readonly shareCapitalCurrency: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly addressCity: string | null;
    readonly youngestMemberYearOfBirth: number | null;
    readonly oldestMemberYearOfBirth: number | null;
    readonly members: CorporationMember[];
}

export interface CorporationProcessOverview {
    readonly id: string;
    readonly lastFetchTry: Date;
    readonly federalStateId: string;
    readonly registerCourtId: string;
    readonly registerNumber: string;
    readonly name: string;
    readonly city: string;
    readonly registered: string | null;
    readonly description: string | null;
    readonly shareCapital: string | null;
    readonly shareCapitalCurrency: string | null;
    readonly street: string | null;
    readonly houseNumber: string | null;
    readonly zip: string | null;
    readonly addressCity: string | null;
    readonly youngestMemberYearOfBirth: number | null;
    readonly oldestMemberYearOfBirth: number | null;
}

export interface CorporationSearchResult {
    readonly corporations: Corporation[];
    readonly totalSize: number;
    readonly searchId: number;
}

export enum CorporationSearchOrder {
    PUBLISHED_DESC = "PUBLISHED_DESC",
    PUBLISHED_ASC = "PUBLISHED_ASC",
}

export interface CorporationSearchRequest {
    readonly federalStateIds: string[];
    readonly registeredFrom: string | null;
    readonly registeredTo: string | null;
    readonly nameQuery: string | null;
    readonly descriptionQuery: string | null;
    readonly yearOfBirthRangeFrom: number | null;
    readonly yearOfBirthRangeTo: number | null;
    readonly shareCapitalFrom: number | null;
    readonly shareCapitalTo: number | null;
    readonly statusesTaner: CorporationStatusTaner[];
    readonly order: CorporationSearchOrder;
    readonly start: number;
    readonly size: number;
    readonly searchId: number;
}

interface CorporationApi {
    search(searchRequest: CorporationSearchRequest): Promise<CorporationSearchResult>;

    updateStatusTaner(id: string, status: CorporationStatusTaner): Promise<void>;

    getByLastFetchStatus(corporationFetchStatus: CorporationFetchStatus): Promise<CorporationOverview[]>;

    getAllProcessOverviewsWithMissingFields(): Promise<CorporationProcessOverview[]>;

    deleteWhereLastFetchStatusIsZeroResults(): Promise<void>;

    generateHtmlContentLink(id: string): string;

    generateXmlContentLink(id: string): string;

    getCorporationAdditionalData(id: string): Promise<CorporationAdditionalData>;

    refetchCorporationDetailSource(id: string): Promise<CorporationDetailSourceStatus>;

    processAndStoreRegisterNumber(registerNumber: number): Promise<CorporationMetaDataResult>;
}

export const corporationApi: CorporationApi = {
    async search(searchRequest) {
        return cloneObject((await axios.post("/api/corporation/_/_search", searchRequest)).data);
    },

    async updateStatusTaner(id, status) {
        await axios.post("/api/corporation/_update-status-taner", null, {
            params: { id, status },
        });
    },

    async getByLastFetchStatus(corporationFetchStatus) {
        return cloneObject(
            (
                await axios.post("/api/corporation/_get-by-last-fetch_status", null, {
                    params: { corporationFetchStatus },
                })
            ).data
        );
    },

    async getAllProcessOverviewsWithMissingFields() {
        return cloneObject((await axios.post("/api/corporation/_get-all-process-overviews-with-missing-fields")).data);
    },

    async deleteWhereLastFetchStatusIsZeroResults() {
        await axios.post("/api/corporation/_delete-where-last-fetch-status-is-zero-results");
    },

    generateHtmlContentLink(id) {
        return `/api/corporation/${id}/html-content/${id}.html`;
    },

    generateXmlContentLink(id) {
        return `/api/corporation/${id}/xml-content/${id}.xml`;
    },

    async getCorporationAdditionalData(id) {
        return cloneObject((await axios.get(`/api/corporation/${id}/corporation-additional-data`)).data);
    },

    async refetchCorporationDetailSource(id) {
        return (await axios.post(`/api/corporation/${id}/_refetch-corporation-detail-source`)).data;
    },

    async processAndStoreRegisterNumber(registerNumber) {
        return (await axios.post(`/api/corporation/${registerNumber}/_processs-and-store-register-number`)).data;
    },
};
